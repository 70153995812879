import * as React from "react"

import Layout from "../components/layout/layout"
import Heading1 from "../components/heading1/heading1"
import CardImage from "../components/card_image/card_image"
import IntLink from "../components/int_link/int_link"
import ExtLink from "../components/ext_link/ext_link"
import { LargeCategoryScore } from "../components/category_score/category_score"
import { PubMap } from "../components/dolna_map/dolna_map"

const Pub = ({ pageContext, location }) => {
  console.log(location)
  const { pub } = pageContext
  let pubUrl = "https://maps.google.com/maps/search/?api=1&query=" + encodeURIComponent(pub.Latitude + "," + pub.Longitude)
  if (pub.GoogleMapsUrl) {
    pubUrl = pub.GoogleMapsUrl
  }
  return (
  <Layout>
    <div className="h-third w-full lg:h-half">
      <CardImage image={pub.Image.localFile} alt={pub.Name} />
    </div>
    <div className="container mx-auto mt-20 text-2xl lg:max-w-screen-lg">
      <div className="py-6 uppercase">
        <IntLink to="/dolna-lista">« Всички долни</IntLink>
      </div>
      <Heading1 className="text-white">{pub.Name}</Heading1>
      <address className="not-italic text-red hover:text-gray-light">
        <ExtLink href={pubUrl}>{pub.Address}</ExtLink>
      </address>
      <div className="flex gap-4 text-5xl my-12">
        <div className="text-2xl flex flex-wrap content-center">сподели в:</div>
        <ExtLink rel="nofollow" title="Facebook" href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(location.href)}>
          <span className="icon-facebook"></span>
          <span className="sr-only">Сподели в facebook</span>
        </ExtLink>
        <ExtLink rel="nofollow" title="LinkedIn" href={"http://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(location.href)}>
          <span className="icon-linkedin"></span>
          <span className="sr-only">Сподели в LinkedIn</span>
        </ExtLink>
        <ExtLink rel="nofollow" title="Twitter" href={"http://twitter.com/share?url=" + encodeURIComponent(location.href)}>
          <span className="icon-twitter"></span>
        </ExtLink>
      </div>

      <div className="max-w-screen-sm">
        {pub.Description}
      </div>

      <div className="flex font text-base flex-col my-8 gap-4 max-w-3xl">
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <LargeCategoryScore name="Ambience" score={pub.Ambience} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <LargeCategoryScore name="Food" score={pub.Food} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <LargeCategoryScore name="Host" score={pub.Host} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <LargeCategoryScore name="Booze" score={pub.Booze} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <LargeCategoryScore name="Budget" score={pub.Budget} />
        </div>
      </div>
    </div>
    <div style={{ height: 450 }}>
      <PubMap lat={pub.Latitude} lng={pub.Longitude} />
    </div>
  </Layout>
  )
}


export default Pub
