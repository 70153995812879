import React from 'react'
import { useState } from 'react'
// import * as Leaflet from 'leaflet'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import MarkerPin from "./dolen-pin.png"
import PubDrawer from "../pub_drawer/pub_drawer"

// var MarkerIcon = Leaflet.icon({
//   iconUrl: MarkerPin
// });

const StadiaTileLayer = () => {
  return (
    <TileLayer
      url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
      attribution='© <a href="https://stadiamaps.com/">Stadia Maps</a>, © <a href="https://openmaptiles.org/">OpenMapTiles</a> © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
    />
  );
}

const DolnaMap = ({ pubs }) => {
  const center = [42.6890335, 23.3265247]
  const [expandedPub, setExpandedPub] = useState(null)

  const closeCallback = () => {
    setExpandedPub(null);
  }


  if (typeof window !== 'undefined') {
    return (
      <div className="flex transition-bounce">
        <MapContainer
         center={center}
         zoom={13}
         className="z-10 grow w-full h-full min-h-full min-h-full-page"
         >
          <StadiaTileLayer/>
          {pubs.map((pub, index) => (
            <Marker
              key={pub.node.id}
              // icon={MarkerIcon}
              position={[pub.node.Latitude, pub.node.Longitude ]}
              eventHandlers={{
                click: () => {
		              setExpandedPub(index)
                },
              }}
            />
            ))
          }
        </MapContainer>
	      {expandedPub && <PubDrawer close={() => { setExpandedPub(null) }} pub={pubs[expandedPub].node}/>}
	    </div>
    )
  }
  return null
}

const PubMap = ({ lat, lng }) => {
  const pubCoords = [lat, lng]
  if (typeof window !== 'undefined') {
    return (
      <MapContainer center={pubCoords} zoom={15} className="h-full">
        <StadiaTileLayer/>
        <Marker
          // icon={MarkerIcon}
          position={pubCoords} />
      </MapContainer>
    )
  }
  return null
}

export { DolnaMap, PubMap }
