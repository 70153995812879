import React, { useState } from 'react'
import Heading2 from "../heading2/heading2"
import CardImage from "../card_image/card_image"
import PubTag from "../pub_tag/pub_tag"
import CategoryScore from "../category_score/category_score"
import ExtLink from "../ext_link/ext_link"
import IntLink from "../int_link/int_link"

const maxScore = 25

const PubDrawer = ({ pub, close }) => {
  let pubUrl = "https://maps.google.com/maps/search/?api=1&query=" + encodeURIComponent(pub.Latitude + "," + pub.Longitude)
  if (pub.GoogleMapsUrl) {
    pubUrl = pub.GoogleMapsUrl
  }
  let score = pub.Ambience + pub.Food + pub.Host + pub.Booze + pub.Budget
  let classes = "font-thin text-2xl mb-24"
  console.log(pub)
  return (
    <div className="z-20 grow w-full basis-0 relative">
      <div onClick={ (e) => close(e) } className="z-20 icon-cross text-red text-5xl p-1 bg-gray-dark absolute -left-14"></div>
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="imageWrapper flex-shrink-0 w-full lg:w-96">
          <CardImage image={pub.Image.localFile} alt={pub.Name} />
        </div>
        <div className="contentWrapper flex-grow">
          <div className="border-b border-gray-medium mb-8 pb-4">
            <div className="flex content-center items-center">
              <Heading2 className="flex-grow">
                <IntLink to={"/dln/"+pub.Slug}>{`${pub.Name} (${score}/${maxScore})`}</IntLink>
              </Heading2>
              <div className="flex gap-4">
                {pub.pub_tags.map(tag => (
                  <PubTag key={tag.Slug} tag={tag} />
                ))}
              </div>
            </div>
            <address className="not-italic text-red hover:text-gray-light">
              <ExtLink href={pubUrl}>{pub.Address}</ExtLink>
            </address>
          </div>
          <p>{pub.Description}</p>
        </div>
      </div>
      <div className="flex flex-col text-sm lg:flex-row mt-4 mb-4">
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <CategoryScore name="Ambience" score={pub.Ambience} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <CategoryScore name="Food" score={pub.Food} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <CategoryScore name="Host" score={pub.Host} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <CategoryScore name="Booze" score={pub.Booze} />
        </div>
        <div className="w-full mb-2 lg:w-auto lg:mb-0 lg:flex-grow">
          <CategoryScore name="Budget" score={pub.Budget} />
        </div>
      </div>
    </div>
  )
}

export default PubDrawer
